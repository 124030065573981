<template>
	<div class="container">
		<a-row
			type="flex"
			justify="center"
			:gutter="8"
			style="padding-left: 5px;"
		>
			<a-result
				status="success"
				title="Seu restaurante foi cadastrado com sucesso"
				sub-title="Enviaremos para o seu email o contrato digital em até 2 dias úteis. Por favor, aguarde"
			>
				<template #extra>
					<p class="welcome">
						Seja muito bem vindo ao portal Agiliza! Todas as
						funcionalidades serão liberadas após a assinatura e
						validação do contrato
					</p>
					<a-button type="dashed" @click="logout()">
						Sair
					</a-button>
					<a-button @click="$router.push('/steps/company-info/')">
						Adicionar outro
					</a-button>
					<a-button type="primary" @click="$router.push('/home')">
						Acessar portal agora
					</a-button>
				</template>
			</a-result>
		</a-row>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'AgreementPage',
	data() {
		return {
			isChecked: false,
		}
	},
	methods: {
		...mapActions('account', ['logout']),
	},
}
</script>

<style lang="less" scoped>
.container {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.65);

	.buttons {
		margin: 40px auto 10px;
	}

	.welcome {
		font-weight: normal;
		margin-bottom: 32px;
	}
}
</style>
